<template>
    <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '', 'mb-3 card']">
        <div>
            <v-container fluid>

                <p><b>{{ $t('reports.lang_downloadLists') }}:</b></p>
                <p>- {{ $t('reports.lang_pleaseSelectListAndPressDownload') }}<br>
                   - {{ $t('reports.lang_dependingOnNumberOfItems') }}<br>
                   - {{ $t('reports.lang_theFormatOfTheListsIsExcel') }}<br></p>



                <v-layout>
                    <v-flex md6 mr-2 ml-2>
                        <v-select outlined
                                  :items="storageReportsSelect"
                                  item-text="name"
                                  item-value="id"
                                  v-model="storageReport"
                                  :label="$t('reports.lang_lists')"
                        ></v-select>
                    </v-flex>

                    <v-flex md6 mr-2 ml-2>
                        <v-btn text color="error" @click="downloadReport">{{ $t('generic.lang_download') }}</v-btn>
                    </v-flex>
                </v-layout>

            </v-container>
        </div>
        <div id="onScreenKeyboardDiv">
            <vue-touch-keyboard class="internalWidth" id="onScreenKeyboard" :options="touchKeyboard.options" :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible" :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                                :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
        </div>
    </div>
</template>



<script>
    import {ENDPOINTS} from '../../config'
    import {Events} from "../../plugins/events";
    import {mapState} from "vuex";
    import mixin from "../../mixins/KeyboardMixIns";
    import moment from "moment";

    export default {
        components: {
        },
        mixins: [mixin],
        data() {
            return {
                ENDPOINTS,
                storageReportsSelect: [
                    {
                        name: this.$t('reports.lang_customerList'),
                        id:"customerlist"
                    },
                    {
                        name: this.$t('reports.lang_itemList'),
                        id:"itemList"
                    },
                    {
                        name: this.$t('reports.lang_itemGroupList'),
                        id:"itemGroupList"
                    },
                ],
                start:null,
                storageReport:"",
                end:null,
            }
        },
        computed: {
            ...mapState([
                'api'
            ])
        },
        methods: {


            downloadReport: function () {
              let apiServerIP = process.env.VUE_APP_API_HOST;
              if(window.location.host != 'localhost' && window.location.host != 'devkasse.3pos.de' && window.location.host != 'kasse.3pos.de'){
                 apiServerIP = location.protocol + "//" + window.location.hostname + ":8010/pos";
              }
                window.open(apiServerIP + "/get/reports/lists/" + this.storageReport + "/?&bs=" + this.api.auth.posID + "&bsid=" + this.api.auth.accessString + "&apiToken=" + this.api.auth.apiWebToken, "_blank");
            },
        },

        mounted() {

        },
    }
</script>